.infos-page-container {
    display: flex;
    flex-direction: column;
    margin-top: 5em;

    & .infos-page-alerts {
        width: 80%;
        background-color: #FFF5F5;
        color: #900;
        text-align: left;
        padding: 1em;
        margin: 0 auto 2em;
        border: 1px solid #f8e6e6;
    }
    
    & .infos-page-consultants-lists-container {
        margin: 1em auto 0;
        width: 80%;
    }
}