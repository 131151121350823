.meeting-page-container {
    display: flex;
    flex-direction: column;
    margin-top: 5em;

    & .meeting-page-body {
        display: flex;
        flex-direction: column;
        margin: 3em auto 0;
        width: 80%;
    }

    & .meeting-page-form-week-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 2em 0;

        & .meeting-page-form-week-form {
            margin: 0 1em;

            & .meeting-page-form-week-label {
                margin: 0 1em;
            }

            & .meeting-page-form-week-input {
                font-family: 'Quicksand';
            }

            & .meeting-page-form-week-input-week-number {
                width: 50px;
            }

            & .meeting-page-form-week-button {
                margin: 0 1em;
                height: 30px;
                text-transform: uppercase;
                font-family: 'Oswald', sans-serif;
                font-size: 0.8em;
                background-color: #900;
                color: #FFF;
                border-radius: 5px;
                border: 1px solid transparent;
            
                &:hover {
                    border: 1px solid #000;
                }
            }
        }
    }

    & .meeting-page-form-list-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & .meeting-page-form-list-button {
            margin: 0 1em;
            height: 30px;
            text-transform: uppercase;
            font-family: 'Oswald', sans-serif;
            font-size: 0.8em;
            background-color: #900;
            color: #FFF;
            border-radius: 5px;
            border: 1px solid transparent;
        
            &:hover {
                border: 1px solid #000;
            }
        }
    }

    & .meeting-page-buttons-container {
        display: flex;
        margin: 1em auto;

        & .meeting-page-button {
            margin: 0 1em;
            padding: 1em;
            height: 50px;
            text-transform: uppercase;
            font-family: 'Oswald', sans-serif;
            background-color: #900;
            color: #FFF;
            border-radius: 5px;
            border: 1px solid transparent;
        
            &:hover {
                border: 1px solid #000;
            }
        }
    }
}