.unauthorized-page-container {
    display: flex;
    flex-direction: column;
    margin-top: 10em;

    & .unauthorized-page-home-button {
        margin: 3em;
        padding: 1em;
        height: 50px;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        background-color: #900;
        color: #FFF;
        border-radius: 5px;
        border: 1px solid transparent;
    
        &:hover {
            border: 1px solid #000;
        }
    }
}