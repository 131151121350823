.reports-list-container {
    display: flex;
    flex-direction: column;
    margin: 2em 5em;

    & .reports-list-searchbar {
        display: flex;
        margin: 1em 0;

        & .reports-list-searchbar-subtitle {
            padding: 1em;
            padding-left: 0;
        }

        & .reports-list-searchbar-container {
            width: 80%;
            display: flex;

            & .reports-list-searchbar-label {
                padding: 1em;
            }

            & .reports-list-searchbar-container-div {
                display: flex;
                flex-direction: column;
           
                & .reports-list-searchbar-input {
                    background-color: #fff;
                    width: 5em;
                    height: 3em;
                    padding: 1em;
                    text-align: center;
                    border: 1px solid #999;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    font-family: "Quicksand";
                }
                
                & .reports-list-searchbar-results {
                    background-color: #fff;
                    height: auto;
                    border: 1px solid #999;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
    
                    & .reports-list-searchbar-results-list {
                        padding: 0.5em 0;
    
                        & .reports-list-searchbar-results-list-item {
                            padding: 0.5em;
                        }
                    }
                }
            }
            
        }

    }
}