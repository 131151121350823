.footer-container {
    font-family: 'Muli';
    background-color: #212121;
    color: #a3a3a3;
    padding: 2em 0;
    font-size: 11px;
    text-align: center;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;

    & .legal-mentions-link {
        margin-left: 0.5em;
        text-decoration: underline;
    }
}