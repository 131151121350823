.legal-mentions-page-container {
    width: 60%;
    margin: 2em auto;
    line-height: 1.2em;

    & .legal-mentions-page-div {
        margin: 2em 0;

        & .legal-mentions-page-subtitle {
            margin: 1em 0;
        }
        
        & .legal-mentions-page-paragraph {
            margin: 1em 0;        
        }
    }
}

