.undone-actions-table-documents-container {
    display: flex;
}

.undone-actions-table-cell-content {
    font-family: "Quicksand";
}

.stat_container {
    height: 1em;
    width: 70%;
    min-width: 10em;
    border-radius: 5px;
    overflow: hidden;
    background-color: #d4d4d4;
    margin-right: 1em;
}

.bar_value{
    height: 100%;
}

// surcharge css to erase bug on table bottom borders

.undone-actions-table-container .css-dsuxgy-MuiTableCell-root {
    line-height: 1 !important;
    padding: 6px 10px;
}