.infos-consultant-page-container {
    display: flex;
    flex-direction: column;
    margin: 3em auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 2em;
    width: 80%;
    
    .infos-consultant-page-buttons-container {
        display: flex;
        margin: 1em auto;

    }
        
    .infos-consultant-page-button {
        margin: 0 1em;
        padding: 1em;
        height: 50px;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        background-color: #900;
        color: #FFF;
        border-radius: 5px;
        border: 1px solid transparent;
    
        &:hover {
            border: 1px solid #000;
        }
    }
}
