.report-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    border: 1px solid #999;
    border-radius: 5px;
    
    &-subtitle-div {
        align-self: flex-start;
        display: flex;
        align-items: center;
    }

    &-subtitle {
        margin-right: 1em;

        &-actions {
            margin: 0.5em 1em 0.5em 0;
        }
    }

    &-item {
        padding: 1em;
        margin: 1em;
        border: 1px solid #999;
        width: 100%;
        text-align: left;
        background-color: #fff;
    }
    
    & .report-client {
        display: flex;
        flex-direction: row;
        align-items: center;

        &-label {
            pointer-events: none;
        }

        &-input {
            width: 20%;
        }

        &-label, &-input, .report-comment-label {
            margin-right: 0.4em;
            padding: 1px;
            font-family: "Quicksand";
            border: none;

            &::-webkit-calendar-picker-indicator{
                display: none;
            }
            
            &:focus {
                &::-webkit-calendar-picker-indicator{
                    display: block;
                }
            }
        }
    }
    
    
    & .report-comment {
        height: 12em;
        
        &-input {
            font-family: "Quicksand";
            text-align: justify;
            line-height: 2;
            height: 100%;
            width: 100%;
            border: none;
            resize: none;

            &:focus {
                outline: none;
            }
        }        
    }
}