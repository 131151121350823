.consultant-form-infos-container {
    display: flex;
    flex-direction: column;
    
    .consultant-form-infos-row {
        display: flex;
        justify-content: space-evenly;
    
        .consultant-form-infos-item {
            display: flex;
            align-items: center;
            justify-content: center;
    
            &-option {
                font-family: 'Quicksand';
            }
        }
    }
}