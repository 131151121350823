.consultant-header-infos-container {
    display: flex;
    flex-direction: column;
    width: 0 auto;

    & .infos-consultant-page-name {
        
        & .infos-consultant-input-name {
            font-family: "Oswald";
            border: none;
            background: none;
            font-size: 1em;
            text-transform: uppercase;
            text-align: center;
            min-width: 50%;
            
            &:focus {
                border: 1px solid #999;
            }
        }
        
        & .infos-consultant-input-firstname {
            text-transform: none;
        }
    }
    
    & .infos-consultant-page-name-paragraph {
        padding: 0.4em;
    }

    & .infos-consultant-page-firstname-paragraph {
        text-transform: none;
    }
    
    & .infos-consultant-page-lastname {
        text-transform: uppercase;
    }
    
    
    & .infos-consultant-page-infos {
        margin: 0.5em;
        
        & .infos-consultant-page-info {
            font-style: italic;
            margin: 0.5em;
            
            & .infos-consultant-input-date {
                font-family: "Quicksand";
                border: none;
                background: none;
                margin-left: 0.3em;
                font-style: italic;
                width: 150px;
                
                &::-webkit-calendar-picker-indicator{
                    display: none;
                }
                
                &:focus {
                    border: 1px solid #999;
                    margin-left: 1em;
                    
                    &::-webkit-calendar-picker-indicator{
                        display: block;
                    }
                }
                
            }
        }
        
        & .infos-consultant-page-date {
            margin: 0.5em 0 0.5em 5em;
        }

        & .infos-consultant-page-date-paragraph {
            margin: 0;
        }
    }   
}