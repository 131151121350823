.actions-list-container {
    display: flex;
    flex-direction: column;
}

.test-textarea {
    font-family: "Quicksand";
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    resize: none;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:focus {
        outline: none;
        resize: none;
        vertical-align: auto;
    }
}
// surcharge Mui css properties

.css-yz9k0d-MuiInputBase-input, .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-family: 'Quicksand' !important;
    font-size: 0.8em !important;
}

 .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    font-family: 'Quicksand' !important;
    font-size: 0.8rem !important;

 }
