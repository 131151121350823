.main-header-container {
    background-color: #FFF;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 0 1em;

    & img {
        width: 200px;
        padding: 0.5em;
    }

    & .main-header-user {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #000;
        height: 80px;
        width: 30%;
        
        & p {
            font-size: 24px;
            font-weight: 700;
        }

        & .main-header-link {
            text-decoration: underline;
            font-weight: bold;
        }

        & .main-header-logout-link {
            margin-top: 1em;
        }

        & .main-header-login-link {
            font-size: 18px;
        }
    }
}