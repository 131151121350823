@import 'reset';
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Quicksand&display=swap');

body {
  font-family: 'Quicksand';
  color: #000;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: #F5F5F5;
  color: #333;

  // ::selection {
  //   background-color: #900;
  //   color: #FFF
  // }

  & h1, h2, h3 {
    font-family: 'Oswald';
  }

  & h1 {
    font-size: 2.5em;
    text-transform: uppercase;
  }

  & h2 {
    font-size: 2em;
  }
}

// overcharge mui css to add our own properties

// change checkboxes color
.css-1s7fuiw-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-1s7fuiw-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #900 !important;
}

// change hover MUI buttons style
.css-uk62km-MuiButtonBase-root-MuiButton-root:hover, .css-9h9mkl-MuiButtonBase-root-MuiButton-root:hover, .css-18hy4jv-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #999 !important;
  border: 1px solid #000;
}