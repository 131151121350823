.consultants-list-table-documents-container {
    display: flex;
}

.consultants-list-table-cell-content {
    font-family: "Quicksand";
}

.stat_container {
    height: 1em;
    width: 70%;
    min-width: 10em;
    border-radius: 5px;
    overflow: hidden;
    background-color: #d4d4d4;
    margin-right: 1em;
}

.bar_value{
    height: 100%;
}

.consultants-list-table-delete-confirmation {
    position: fixed;
    top: 50%;
    left: 50%;
    // right: 0;
    transform: translate(-50%, -50%);
    box-shadow: 24;
    width: 20%;
    height: 20%;
    background-color: #FFF;
    border: 1px solid #d4d4d4;
    font-family: "Quicksand";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    & button {
        padding: 1em 1.5em;
        margin: 0 1em;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        background-color: #900;
        color: #FFF;
        border-radius: 5px;
        border: 1px solid transparent;
    
        &:hover{
            border: 1px solid #000;
        }
    }
}

// surcharge css to erase bug on table bottom borders

.consultants-list-table-container .css-dsuxgy-MuiTableCell-root {
    line-height: 1 !important;
    padding: 6px 10px;
}