.connexion-page-container {
    width: 40%;
    border: 1px solid #ddd;
    padding: 2em 1em;
    margin: 5em auto;
    
    & .connexion-page-title {
        background-color: #F5F5F5;
        color: #333;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 500;
        text-align: left;
        margin: 1em 0;
    }

    & .connexion-page-error-container {
        background-color: #FFF5F5;
        color: #900;
        text-align: left;
        padding: 1em;
        margin-bottom: 2em;
        border: 1px solid #f8e6e6;
    }

    & .connexion-page-form-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

   
        & .connexion-page-form-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 2em;
        }

        & .connexion-page-form-label {
            margin: 0.5em 0;
            color: #000;
        }

        & .connexion-page-form-input {
            width: 100%;
            height: 50px;
            border: 1px solid #ddd;
            border-radius: 5px;
            padding: 0 1em;
            font-family: "Quicksand";

            &:focus{
                border: 2px solid #000;
            }
        }

        & .connexion-page-form-button {
            width: 100px;
            height: 50px;
            text-transform: uppercase;
            font-family: 'Oswald', sans-serif;
            background-color: #900;
            color: #FFF;
            border-radius: 5px;
            border: 1px solid transparent;

            &:hover{
                border: 1px solid #000;
            }
        }
    }
}
