.top-header-container {
    font-family: 'Muli';
    background-color: #2f2f2f;
    color: #fff;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    
    & .top-header-link {
        display: flex;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        justify-content: flex-end;
        margin: 0 3em;

        & p {
            margin-left: 1em;
        }
    }
}