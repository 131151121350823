.meeting-list-container {
    margin: 2em auto 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    & .meeting-list-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & .meeting-list-subtitle {
            align-self: flex-end;
            margin: 1em 0;
        }

        & .meeting-list-add-button {
            padding: 1em;
            margin: 1em 0;
            height: 50px;
            text-transform: uppercase;
            font-family: 'Oswald', sans-serif;
            background-color: #900;
            color: #FFF;
            border-radius: 5px;
            border: 1px solid transparent;
        
            &:hover{
                border: 1px solid #000;
            }
        }
    }

}