.navbar-container {
    background-color: #900;
    height: 90px;
    display: flex;
    margin-top: 0.5em;
    
    & .navbar-list {
        display: flex;
        margin: auto;

        &-item {
            color: #d4d4d4;
            font-size: 22px;
            font-family: 'Oswald';
            margin: 0 2em;
        }
        
        & .navbar-list-current-item {
            color: #FFF;
            background-color: rgba(212, 212, 212, 0.2);
            border-radius: 5px;
            padding: 0.1em 0.5em;
        }
    }
    
}
