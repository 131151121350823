.consultants-list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2em 0;
    
    .consultants-list-title {
        margin: 0.5em 0;
    }
    
    .consultants-list-table-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .consultants-list-add-button {
        align-self: flex-end;
        padding: 1em;
        height: 50px;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        background-color: #900;
        color: #FFF;
        border-radius: 5px;
        border: 1px solid transparent;
    
        &:hover{
            border: 1px solid #000;
        }
    }
}


// overcharge MUI default css properties
.css-11yukd5-MuiTabs-indicator {
    background-color: #900 !important;
}

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #900 !important;
}