.meeting-consultant-page-container {
    display: flex;
    flex-direction: column;
    margin: 3em auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 2em;
    width: 80%;

    & .meeting-consultant-page-header-nav {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    & .meeting-consultant-page-last-report, .meeting-consultant-page-new-report {
        margin: 1em 5em;
        display: flex;
        flex-direction: column;

        & .meeting-consultant-page-subtitle-report {
            font-size: 1.5em;
            align-self: flex-start;
        }

        & .meeting-consultant-page-subtitle-report-week {
            margin: 1em;
        }
    }

    & .meeting-consultant-page-archive-button, .meeting-consultant-page-button {
        margin: 1em 1em 2em;
        padding: 1em;
        height: 50px;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        background-color: #900;
        color: #FFF;
        border-radius: 5px;
        border: 1px solid transparent;
    
        &:hover {
            border: 1px solid #000;
        }
    }
}

.meeting-consultant-page-modal-error {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 400px;
//   background-color: #FFF;
//   border: 2px solid #000;
//   box-shadow: 24;
//   padding: 4em;
    position: fixed;
    top: 50%;
    left: 50%;
    // right: 0;
    transform: translate(-50%, -50%);
    box-shadow: 24;
    width: 40%;
    height: 20%;
    background-color: #FFF;
    border: 1px solid #d4d4d4;
    font-family: "Quicksand";
    font-size: 1.3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    & button {
        padding: 1em 1.5em;
        margin: 0 1em;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        background-color: #900;
        color: #FFF;
        border-radius: 5px;
        border: 1px solid transparent;
    
        &:hover{
            border: 1px solid #000;
        }
    }
}