.app-container {
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 5em;

    & .main-container {
        text-align: center;
        margin : 2em;
    }
}